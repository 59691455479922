import React, { Component } from "react";
import { gql } from "apollo-boost";
import { Form, Icon, Input, Button, Row, Col } from "antd";

import CredentialsContext from "../contexts/credentials";
import ApolloInterface from "../Apollo";

import "../styles/NewQuestionnaire.css";

class NewQuestionnaire extends Component {
  static contextType = CredentialsContext;

  handleSubmit = (e) => {
    e.preventDefault();

    const sample_id = this.props.form.getFieldValue("sample_id");
    const email = this.props.form.getFieldValue("email");
    const admin_email = this.props.form.getFieldValue("admin_email");

    this.addNewQuestionnaire(sample_id, email, admin_email);
  };

  addNewQuestionnaire = (sample_id, email, admin_email) => {
    const variables = { sample_id, email, admin_email };
    const gqlQuery = gql`
      mutation CreateQuestionnaire(
        $sample_id: String!
        $email: String!
        $admin_email: String!
      ) {
        createQuestionnaire(
          questionnaireInput: {
            sample_id: $sample_id
            email: $email
            admin_email: $admin_email
          }
        ) {
          _id
          sample_id
          email
          admin_email
          pin
        }
      }
    `;
    ApolloInterface.runMutation(this.context.token, variables, gqlQuery)
      .then((res) => {
        this.props.history.push("/admin/questionnaires");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="NewQuestionnaire">
        <Row>
          <Col span={16} offset={4}>
            <h1>New questionnaire</h1>
            <Form
              onSubmit={this.handleSubmit}
              className="newquestionnaire-form"
            >
              <Form.Item>
                {getFieldDecorator("sample_id", {
                  rules: [
                    {
                      required: true,
                      message: "Please input name of the person!",
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Sample ID"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [{ required: true, message: "Please input Email!" }],
                })(
                  <Input
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="email"
                    placeholder="E-mail"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("admin_email", {
                  rules: [{ required: true, message: "Please input Admin Email!" }],
                })(
                  <Input
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="email"
                    placeholder="Admin E-mail"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="newquestionnaire-button"
                >
                  Create questionnaire
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create({ name: "newquestionnaire" })(NewQuestionnaire);

import React, { Component } from "react";
import { Row, Col, Form, Icon, Input, Button } from "antd";

import CredentialsContext from "../contexts/credentials";
import "../styles/Login.css";
import ApolloInterface from "../Apollo";
import Logo from "../images/2019_pgp-logo_pl.png";

class Login extends Component {
  componentDidMount = () => {
    this.mainInput.focus();
  };

  mainInput = React.createRef();

  static contextType = CredentialsContext;
  handleSubmit = (event) => {
    event.preventDefault();

    let wrong = false;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log('Received values of form: ', values);
      } else {
        wrong = true;
      }
    });

    if (wrong) {
      return;
    }

    const username = this.props.form.getFieldValue("username");
    const password = this.props.form.getFieldValue("password");

    ApolloInterface.login(username, password)
      .then((res) => {
        this.context.login(
          username,
          res.data.login.token,
          res.data.login.userId,
          res.data.login.tokenExpiration
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="Login">
        <Row>
          <Col span={6} offset={9} style={{ marginTop: "60px" }}>
            <Row>
              <Col span={20} offset={2}>
                <img src={Logo} alt="GMP logo" style={{ width: "60%" }} />
                {/*<h1>Genomiczna Mapa Polski</h1>*/}
                <Form
                  onSubmit={this.handleSubmit}
                  className="login-form"
                  style={{ margin: "auto" }}
                >
                  <Form.Item>
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ],
                    })(
                      <Input
                        ref={(input) => {
                          this.mainInput = input;
                        }}
                        prefix={
                          <Icon
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Username"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="password"
                        placeholder="Password"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create({ name: "login" })(Login);

import React, { Component } from "react";
import { gql } from "apollo-boost";
import { Col, Row, Button,Table, Input } from "antd";
import { NavLink } from "react-router-dom";

import CredentialsContext from "../contexts/credentials";

import "../styles/Users.css";
import ApolloInterface from "../Apollo";

import { usersColumns } from "../utils/dataColumns";

import { containsPhrase } from "../utils/string";

class Users extends Component {
  state = {
    data: [],
    selectedRowKeys: [],
    searchPhrase: "",
  };

  static contextType = CredentialsContext;

  back = () => {
    this.props.history.push("/");
  };

  fetchData = () => {
    const token = this.context.token;
    const gqlQuery = gql`
      {
        users {
          _id
          username
        }
      }
    `;
    ApolloInterface.runQuery(token, gqlQuery)
      .then((res) => {
        return this.setState({
          data: res.data.users.map((user, index) => {
            user.key = index;
            return user;
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  deleteSelected = (e) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${this.state.selectedRowKeys.length} user(s)`
      )
    ) {
      return;
    }
    const ids = this.state.selectedRowKeys.map((v) => this.state.data[v]._id);

    const variables = { ids };
    const gqlQuery = gql`
      mutation DeleteUsers($ids: [String!]!) {
        deleteUsers(ids: $ids)
      }
    `;
    ApolloInterface.runMutation(this.context.token, variables, gqlQuery)
      .then(() => {
        this.fetchData();
        this.clearSelection();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSearchPhraseChanged = (e) => {
    this.setState({ searchPhrase: e.target.value });
  };

  filterData = (data) => {
    const p = this.state.searchPhrase;

    if (p === "") {
      return data;
    }

    const fields = ["username"];

    return data.filter((v) => {
      for (let i = 0; i < fields.length; ++i) {
        const f = fields[i];
        if (containsPhrase(String(v[f]), p)) return true;
      }

      return false;
    });
  };

  clearSelection = (e) => {
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className="Users">
        <Col span={24}>
          <Row gutter={[0, 50]}>
            <Col span={17}>
              <Row style={{ textAlign: "left" }}>
                <NavLink to="/admin/new-user">
                  <Button style={{ marginRight: "10px" }}>New user</Button>
                </NavLink>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={this.clearSelection}
                >
                  Clear selection
                </Button>
                <Button onClick={this.deleteSelected}>Delete selected</Button>
              </Row>
            </Col>
            <Col span={7}>
              <Input
                placeholder="Search"
                value={this.state.searchPhrase}
                onChange={this.onSearchPhraseChanged}
              />
            </Col>
          </Row>
          <Row>
            <Table
              rowSelection={rowSelection}
              dataSource={this.filterData(this.state.data)}
              columns={usersColumns}
            />
          </Row>
        </Col>
      </div>
    );
  }
}

export default Users;

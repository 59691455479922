import React, { Component } from "react";
import ApolloInterface from "../Apollo";
import { gql } from "apollo-boost";
import { Form, Icon, Input, Button, Row, Col } from "antd";

import CredentialsContext from "../contexts/credentials";

import "../styles/NewUser.css";

class NewUser extends Component {
  static contextType = CredentialsContext;

  handleSubmit = (e) => {
    e.preventDefault();

    let wrong = false;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log('Received values of form: ', values);
      } else {
        wrong = true;
      }
    });

    if (wrong) {
      return;
    }

    const username = this.props.form.getFieldValue("username");
    const password = this.props.form.getFieldValue("password");

    this.addNewUser(username, password);
  };

  addNewUser = (username, password) => {
    const variables = { username: username, password: password };

    const gqlQuery = gql`
      mutation CreateUser($username: String!, $password: String!) {
        createUser(userInput: { username: $username, password: $password }) {
          _id
          username
        }
      }
    `;
    ApolloInterface.runMutation(this.context.token, variables, gqlQuery)
      .then((res) => {
        this.props.history.push("/admin/users");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="NewUser">
        <Row>
          <Col span={16} offset={4}>
            <h1>New user</h1>
            <Form onSubmit={this.handleSubmit} className="newuser-form">
              <Form.Item>
                {getFieldDecorator("username", {
                  rules: [
                    { required: true, message: "Please input username!" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Username"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input password!" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="newuser-form-button"
                >
                  Create user
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create({ name: "newuser" })(NewUser);

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import CredentialsContext from "./contexts/credentials.js";
import Login from "./components/Login";
import Home from "./components/Home";
import Questionnaire from "./components/Questionnaire";
import Thanks from "./components/Thanks";
import Policy from "./components/Policy";

import "antd/dist/antd.css";

import "./App.css";
import "./styles/Global.css"

class App extends Component {
  state = {
    userId: localStorage.userId,
    token: localStorage.token,
    tokenExpiration: localStorage.tokenExpiration,
  };

  getToken = () => {
    if (this.state.token) {
      if (this.state.tokenExpiration < Date.now()) {
        return null;
      }
      return this.state.token;
    }
    return null;
  };

  login = (username, token, userId, tokenExpiration) => {
    localStorage.username = username;
    localStorage.token = token;
    localStorage.userId = userId;
    tokenExpiration = Date.now() + (tokenExpiration - 1) * 60 * 1000;
    localStorage.tokenExpiration = tokenExpiration;
    this.setState({
      token: token,
      userId: userId,
      tokenExpiration: tokenExpiration,
    });
  };

  logout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("tokenExpiration");
    this.setState({ token: null, userId: null, tokenExpiration: null });
  };

  componentDidUpdate = () => {
    //console.log(this.state);
  };

  routesWhenLoggedOut = () => {
    return (
      <Switch>
        <Route path="/login/" component={Login} />
        <Route path="/quest/:hash" component={Questionnaire} />
        <Route path="/thanks/" component={Thanks} />
        <Route path="/policy/" component={Policy} />
        <Redirect from="/" to="/login" />
      </Switch>
    );
  };

  routesWhenLoggedIn = () => {
    return (
      <Switch>
        <Route path="/admin/" component={Home} />
        <Route path="/quest/:hash" component={Questionnaire} />
        <Redirect from="/" to="/admin" />
      </Switch>
    );
  };

  generateRoutes = () => {
    //console.log(this.getToken());
    if (this.getToken()) {
      //console.log('Logged IN');
      return this.routesWhenLoggedIn();
    } else {
      //console.log('Logged OUT');
      return this.routesWhenLoggedOut();
    }
  };

  render() {
    return (
      <BrowserRouter>
        <CredentialsContext.Provider
          value={{
            token: this.state.token,
            tokenExpiration: this.state.tokenExpiration,
            userId: this.state.userId,
            login: this.login,
            logout: this.logout,
          }}
        >
          <div className="App" style={{ height: "100%" }}>
            {this.generateRoutes()}
          </div>
        </CredentialsContext.Provider>
      </BrowserRouter>
    );
  }
}

export default App;
